import React from "react"

import RightArrow from "../../../../images/Services/Qa/right-arrow.svg"

import "../QaRoadmap.scss"

export default function QaRoadmap({ accents }) {
  return (
    <section className="qa">
      <div className="qa-accents">
        <div className="container">
          {accents.map((accents, index) => (
            <div key={index} className="row qa-accents-row">
              <h3 className="qa-accents-title col-lg-5 col-md-6 col-sm-6 col-xs-12">
                {accents.title}
              </h3>
              <ul className="qa-accents-list col-lg-7 col-md-6 col-sm-6 col-xs-12">
                {accents.articles.map((accent, idx) => (
                  <li key={idx} className="qa-accents-list_item">
                    <img src={RightArrow} alt="->" />
                    <p>{accent}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
