import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import HeaderSection from "../../components/Services/ServiceHeader"
import ServiceDescription from "../../components/SingleService/Common/ServiceDescription"
import QaAccents from "../../components/SingleService/Qa/QaAccents"
import "../../components/SingleService/Startups/StartupsRoadmap.scss"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForQa from "../../information/Services/dataForQa"
import { mainUrl } from "../../js/config"


export default function Startups() {
  const { header, accents, articles } = dataForQa
  return (
    <section className="startup">
      <SEO
        title="Quality Assurance Services for Startup"
        description="QA phase is an important step of every development process. OS-System offers software testing services for your business."
        canonical={`${mainUrl}/services/qa/`}
      />
      <Layout showFormPoint={50}>
        <HeaderSection title={header.title} BG={header.background} />
        <ServiceDescription articles={articles} />
        <QaAccents accents={accents} />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}
