import { QaBG } from "../../images/Services/Backgrounds"

import Map from "../../images/Services/Qa/map.png"

const dataForQa = {
  service: "qa",
  header: {
    title: "Quality Assurance",
    background: QaBG,
  },
  articles: {
    image: Map,
    text: [
      "<strong></strong><strong>Though projects come in different shapes and sizes, we always keep the focus on delivering quality.</strong><br/><br/> To support this execution, we have a quality leader on each project team to facilitate peer reviews and support the QA performance of each project team. This means that if we work on your project, we make it our first priority and dedicate an optimal resource pool to accomplish it.",
    ],
  },
  accents: [
    {
      title: "Why is the QA phase important to the project?",
      articles: [
        "QA Prevents Catastrophic Corporate Emergencies",
        "QA Promotes Organization, Productivity and Efficiency",
        "QA Maintains Great User Experience",
        "Boosts Customer Satisfaction",
        "QA Brings In More Profit",
      ],
    },
  ],
  projects: [
    {
      id: "210",
    },
    {
      id: "230",
    },
  ],
}

export default dataForQa
